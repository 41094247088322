import React from "react";
// import heartImg from "../assets/images/heart-full.png";
// import fullheartImg from "../assets/images/heart-img.png";
import cardiovascular from "../assets/images/systems/cardiovascular.png";
import endocrine from "../assets/images/systems/endocrine.png";
import digestive from "../assets/images/systems/digestive.png";
import immune from "../assets/images/systems/immune.png";
import senses from "../assets/images/systems/senses.png";
import lymphatic from "../assets/images/systems/lymphatic.png";
import skeletal from "../assets/images/systems/skeletal.png";
import nervous from "../assets/images/systems/nervous.png";
import reproductive from "../assets/images/systems/reproductive.png";
import respiratory from "../assets/images/systems/respiratory.png";
import renal from "../assets/images/systems/renal.png";
import TopCourseCard from "./inc/TopCourseCard";

const TopCourses = () => {
  return (
    <div className="py-12 md:px-32">
      <div className="px-[20px] w-[100%]">
        <div className="grid grid-cols-5 gap-4 md:gap-4">
          <div className="col-span-5 md:col-span-3">
            <h4 className="font section-header">
              Top <span className="bluetext">Featured Courses</span> to check on
              the Application
            </h4>
          </div>
          {/* <div className="md:col-span-2 hidden lg:grid justify-items-end align-start hidden">
            <button className="font bluetext bg-border text-xs rounded-[10px] border-[1px] px-[24px] py-2 mt-[26px] hidden">
              View Courses
            </button>
          </div> */}
        </div>
        <p className="font mt-[18px]">
          At DigiBridge, we offer a comprehensive selection of essential
          foundational courses that can elevate your academic performance to new
          heights. Our team has meticulously selected a range of courses that
          provide a distinct learning experience for students.
        </p>
        <p className="font mt-[18px]">
          Our experienced educators are passionate about their field and
          committed to guiding students towards success through our featured
          courses. Uncover the advantages of learning with our app by exploring
          our range of featured courses.
        </p>
        {/* <div className="lg:hidden hidden">
          <button className="font bluetext bg-border text-xs rounded-[10px] border-[1px] px-[24px] py-3 mt-[26px]">
            View Courses
          </button>
        </div> */}
      </div>
      <div className="flex">
        {/* <div className="lg:hidden px-5 pt-10 grid grid-cols-6 gap-4 md:gap-4 mx-auto">
          <TopCourseCard image={heartImg} />
          <TopCourseCard image={heartImg} />
          <TopCourseCard image={heartImg} />
          <TopCourseCard image={heartImg} />
          <TopCourseCard image={heartImg} />
          <TopCourseCard image={heartImg} />
        </div> */}
        <div className="grid px-5 pt-10 grid grid-cols-8 gap-4 md:gap-4 mx-auto">
          <TopCourseCard image={cardiovascular} text="Cardiovascular System" />
          <TopCourseCard image={endocrine} text="Endocrine System" />
          <TopCourseCard image={digestive} text="Gastrointestinal System" />
          <TopCourseCard image={immune} text="Immune System" />
          <TopCourseCard image={senses} text="Special Senses" />
          <TopCourseCard image={lymphatic} text="Lymphatic System" />
          <TopCourseCard image={skeletal} text="Muskuloskeletal System" />
          <TopCourseCard image={nervous} text="Nervous System" />
          <TopCourseCard image={reproductive} text="Reproductive System" />
          <TopCourseCard image={respiratory} text="Respiratory System" />
          <TopCourseCard image={renal} text="Urinay System" />
        </div>
      </div>
    </div>
  );
};

export default TopCourses;
