import React from "react";

const TopCourseCard = ({ image, text }) => {
  return (
    <div
      className="col-span-4 md:col-span-2 rounded-lg mb-2"
      style={{ boxShadow: "0 2px 12px rgba(0, 0, 0, 0.15)" }}
    >
      <div className="">
        <img className="rounded-lg" src={image} alt="courses" width="350px" />
      </div>
      <div className="p-3">
        <p className="font text bluetext">
          <b>{text}</b>
        </p>
      </div>
    </div>
  );
};

export default TopCourseCard;
