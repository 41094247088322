import React from "react";

const CourseCard = ({ boldText, otherText, src }) => {
  return (
    <div className=" flex flex-row course-card w-[100%] h-[76px] mt-[8px] p-[8px] lg:w-[320px] lg:h-[120px] lg:p-[16px] lg:items-center">
      <img
        src={src}
        alt={"icon"}
        className=" course-card-icon lg:w-[96px] lg:h-[96px]"
      />
      <div>
        <p className="font font-semibold text-xl card-text-bold">{boldText}</p>
        <p className="font text-sm">{otherText}</p>
      </div>
    </div>
  );
};

export default CourseCard;
