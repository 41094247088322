import React from "react";
// import "../assets/css/digibridge.css";
import blueImg from "../assets/images/icons/blue-head.png";

const CardBlue = ({ boldText, otherText }) => {
  return (
    <div className="card-blue ">
      <img src={blueImg} alt={""} className=" w-[24px] h-[24px]" />

      <p className="font font-semibold text-xl card-text-bold pb-2">
        {boldText}
      </p>
      <p className="font text-sm md:text-base">{otherText}</p>
    </div>
  );
};

export default CardBlue;
