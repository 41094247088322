import React from "react";
import "./inc/inc.css";

import research from "../assets/images/icons/research.png";
import fon from "../assets/images/icons/fon.png";
import medsurg from "../assets/images/icons/medsurg.png";
import appStore from "../assets/images/icons/app-store.png";
import googlePlay from "../assets/images/icons/google-play.png";
import mobileApp from "../assets/images/icons/mobile-1.png";

const HeroHeader = () => {
  return (
    <section
      className="px-5 md:mx-32 lg:mt-[92px] lg:flex lg:items-start lg:justify-between lg:px-[0px] lg:py-[70px]"
      id="home"
    >
      <div className=" mx-auto w-[99%] lg:w-[50%]">
        <div className="mb-[8px]">
          <p className="text-left text-2xl font-bold mb-2 leading-9 font font-bold lg:text-[42px] lg:pb-[20px] lg:leading-[50px]">
            Welcome to enhanced learning with{" "}
            <span className="bluetext">DigiBridge</span>
          </p>
          <p className="text-left text-base font-medium tracking-tight lg:text-xl">
            Our application offers a range of features that can help you achieve
            your goals. If you're looking to improve your productivity, track
            your progress, or simply stay organized, Digibridge is your go-to.
          </p>
        </div>
        <div className="flex flex-row mt-[8px] lg:mt-[29px]">
          <img
            src={medsurg}
            alt="medsurg icon"
            className="rounded-full w-[25px] h-[25px] mr-[15px]"
          />
          <p className="text-left text-base font-light lg:text-xl">
            Well Detailed Diagrams
          </p>
        </div>
        <div className="flex flex-row mt-[8px] lg:mt-[18px]">
          <img
            src={research}
            alt="research icon"
            className="rounded-full w-[25px] h-[25px] mr-[15px]"
          />
          <p className="text-left text-base font-light lg:text-xl">
            Easy to Read Noted
          </p>
        </div>
        <div className="flex flex-row mt-[8px] mb-[10px] lg:mt-[18px]">
          <img
            src={fon}
            alt="fon icon"
            className="rounded-full w-[25px] h-[25px] mr-[15px]"
          />

          <p className="text-left text-base font-light lg:text-xl">
            Accurate Nursing Facts
          </p>
        </div>
        <div className="flex flex-row mt-[18px] space-between w-[173px]  lg:mt-[40px]">
          <img
            src={googlePlay}
            alt="GooglePlay icon"
            width="100%"
            className="pr-1"
          />
          <img src={appStore} alt="AppStore icon" className="w-[100%] pl-1" />
        </div>
      </div>
      <div className="mt-[48px] lg:mt-[0px] lg:w-[45%] mx-auto w-[99%] ">
        <img src={mobileApp} alt="Mobile-App-sample" className=" lg:w-[100%]" />
      </div>
      {/* <div className="mt-[48px] hidden lg:visible lg:w-[40%] ">
                <img
                src={mobileApp}
                alt="Mobile-App-sample"
                className="w-[266px] h-[557px]"/>
            </div> */}
    </section>
  );
};

export default HeroHeader;
