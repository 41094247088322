import React from "react";
// import testimonial from "../assets/images/testimonial.png";
import Israel from "../assets/images/Israel.jpg";
import Adetayo from "../assets/images/Adetayo.jpg";
import Glory from "../assets/images/Glory.jpg";
import semeton from "../assets/images/semeton.jpg";
import TestimonialCard from "./inc/TestimonialCard";

const Testimonial = () => {
  return (
    <div className="py-12 md:px-24">
      <div className="px-[20px] md:px-0 w-[100%]">
        <h4 className="font section-header font-center">Trusted by Many</h4>
        <h4 className="font section-header font-center bluetext">
          Registered Nurses
        </h4>
        <p className="font mt-[18px] text-sm text-center lg:px-60">
          We take great pride in offering our users an all-inclusive application
          that has undergone rigorous testing, robust security measures that
          protect your data and proven to deliver exceptional outcomes.
        </p>
      </div>
      <div className="flex justify-center items-center justify-items-center">
        <div className="px-5 pt-10 grid grid-cols-4 gap-5 md:gap-5 mx-auto">
          <TestimonialCard
            image={Israel}
            name="Fawole Israel (RN, BNSc)"
            description="Registered Nurse and Speaker of Oyo State Youth Parliament"
          />
          <TestimonialCard
            image={Adetayo}
            name="Adetayo Adepoju (RN, BNSc.)"
            description="St. Dominic Catholic Hospital Ibadan, Oyo State"
          />
          <TestimonialCard
            image={semeton}
            name="Semeton Balogun (RN, BNSc.)"
            description="Lagos State Health Commission"
          />
          <TestimonialCard
            image={Glory}
            name="Glory Idigbe (RN, BNSc.)"
            description="Lutheran Hospital, Yahe, Yala Local Government, Cross River State"
          />
          {/* <TestimonialCard image={testimonial} />
          <TestimonialCard image={testimonial} />
          <TestimonialCard image={testimonial} />
          <TestimonialCard image={testimonial} /> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
