import React, { useState } from "react";
import fullLogo from "../../assets/images/logos/full-logo.png";
import { ServerLink } from "../keys/Links";
import PostApi from "../Api/PostApi";
import Swal from "sweetalert2";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const subscribe = (e) => {
    e.preventDefault();
    setLoading(true);
    if (email === "") {
      setError(true);
      setErrorMessage("Email field cannot be blank");
      setLoading(false);
    } else {
      const data = {
        email: email,
      };
      PostApi(ServerLink + "/subscribe", data)
        .then((res) => {
          if (res.status === true) {
            Swal.fire({
              icon: "Success",
              text: res.message,
              confirmButtonColor: "#1437E9",
            });
            console.log("res", res);
            setLoading(false);
            setTimeout(() => {
              window.location.replace("/");
            }, 3000);
          } else {
            Swal.fire({
              text: res.message,
              icon: "error",
              confirmButtonColor: "#1437E9",
            });
            console.log("error", res.message);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  return (
    <div>
      <div>
        <div className="py-16">
          <div className="px-[20px] w-[100%] lg:pt-12 pt-4">
            <h4 className="font section-header font-center">
              Get the <span className="bluetext">Latest Information</span> On
              DigiBridge
            </h4>
            <p className="font mt-[18px] text-xs text-center">
              Take the lead today, stay updated and start experiencing our many
              valuable offerings.
            </p>
          </div>
          <div className="px-6 pt-6" id="contact">
            <form action="">
              <div className="row">
                <div className="flex items-center justify-center">
                  <div className="col-12 md:w-1/5">
                    <div className="relative mb-6">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                        </svg>
                      </div>
                      <input
                        type="email"
                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-full pl-10 dark:bg-white-700 dark:border-gray-100 dark:placeholder-gray-400 dark:text-dark dark:focus:ring-blue-500 dark:focus:border-blue-500 px-4 mr-6 py-4 md:py-3"
                        placeholder="Input your Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      {error ? (
                        <p className="text-danger">{errorMessage}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="relative mb-6 mx-2">
                      {loading ? (
                        <button
                          type="button"
                          className="bg-blue-500 text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 cursor-not-allowed"
                          data-te-ripple-init
                          disabled
                        >
                          Sending...
                        </button>
                      ) : email === "" ? (
                        <button
                          type="button"
                          className="bg-gray-400 text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2 cursor-not-allowed"
                          data-te-ripple-init
                          disabled
                        >
                          Subscribe
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="bg-primary text-white py-3 px-4 rounded-lg m-0 md:px-12 md:py-2"
                          data-te-ripple-init
                          onClick={subscribe}
                        >
                          Subscribe
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <section style={{ background: "#F9FAFF" }}>
            <div className="flex justify-between pt-12">
              <a href="/" className="mx-auto">
                <img className="mx-auto" src={fullLogo} alt="Digibridge Logo" />
              </a>
            </div>
            <div className="font text-sm max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
              <div className="flex justify-center mt-8 space-x-6">
                <div className="border bg-border rounded-full p-2">
                  <a
                    href="https://www.facebook.com/profile.php?id=100090103114034&mibextid=LQQJ4d"
                    className="text-gray-700 hover:text-black"
                  >
                    <span className="sr-only">Facebook</span>
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="border bg-border rounded-full p-2">
                  <a
                    href="https://www.instagram.com/p/CoUaRF1I07O/?igshid=YmMyMTA2M2Y="
                    className="text-gray-700 hover:text-black"
                  >
                    <span className="sr-only">Instagram</span>
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="border bg-border rounded-full p-2">
                  <a
                    href="https://twitter.com/digibridgern"
                    className="text-gray-700 hover:text-black"
                  >
                    <span className="sr-only">Twitter</span>
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                    </svg>
                  </a>
                </div>
                <div className="border bg-border rounded-full p-2 align-middle">
                  <a
                    href="https://www.facebook.com/profile.php?id=100090103114034&mibextid=LQQJ4d"
                    className="text-gray-700 hover:text-black"
                  >
                    <span className="sr-only">Facebook</span>
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
              <nav className="flex flex-wrap justify-center -mx-5 -my-2">
                <div className="px-2 py-2">
                  <a
                    href="/"
                    className="text-sm leading-6 text-gray-500 hover:text-gray-900"
                  >
                    Courses
                  </a>
                </div>
                {/* <div className="px-2 py-2">
                  <a
                    href="/"
                    className="text-sm leading-6 text-gray-500 hover:text-gray-900"
                  >
                    Pricing
                  </a>
                </div>
                <div className="px-2 py-2">
                  <a
                    href="/"
                    className="text-sm leading-6 text-gray-500 hover:text-gray-900"
                  >
                    FAQs
                  </a>
                </div> */}
                <div className="px-2 py-2">
                  <a
                    href="/"
                    className="text-sm leading-6 text-gray-500 hover:text-gray-900"
                  >
                    About Us
                  </a>
                </div>
                {/* <div className="px-2 py-2">
                  <a
                    href="/"
                    className="text-sm leading-6 text-gray-500 hover:text-gray-900"
                  >
                    Privacy Policy
                  </a>
                </div> */}
              </nav>
              <p className="mt-8 text-base text-sm leading-6 text-center text-gray-400">
                © DigiBridge Copyright 2023.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Footer;
