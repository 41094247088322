import React from "react";

import CourseCard from "./courseCard";
import medsurg from "../assets/images/icons/medsurg.png";
import fon from "../assets/images/icons/fon.png";
import mgt from "../assets/images/icons/mgt.png";
import phc from "../assets/images/icons/phc.png";
import pharmac from "../assets/images/icons/pharmac.png";
import mental from "../assets/images/icons/mental.png";
import research from "../assets/images/icons/research.png";

const Courses = () => {
  return (
    <div className="course-section py-[48px] px-[20px] md:px-32" id="course">
      <div className="px-[20px] w-[100%]">
        <p className="font font-bold text-xl text-center lg:text-4xl">
          Courses Categories available on{" "}
          <span className="bluetext">DigiBridge</span>{" "}
        </p>
        <p className="font mt-[18px] text-center text-sm lg:px-11 lg:text-lg">
          Welcome to our nursing courses page! Our nursing courses are developed
          to provide students with the required knowledge, skills, and
          experience to excel in the field of nursing. We offer a range of
          courses that cater to different students’ interests and skill levels.
        </p>
      </div>
      <div className="mt-[36px] lg:flex lg:grid  grid-cols-1 gap-4 lg:flex-col lg:justify-items-center lg:content-evenly">
        <div className="w-[100%] lg:w-[66%] lg:grid grid-cols-2 lg:justify-items-center">
          <CourseCard
            src={medsurg}
            boldText="MedSurg Nursing"
            otherText="11 Systems Available"
          />
          <CourseCard
            src={fon}
            boldText="Foundation of Nursing"
            otherText="9 Courses Available"
          />
        </div>
        <div className="w-[100%] lg:flex lg:justify-around">
          <CourseCard
            src={mgt}
            boldText="Nursing Management"
            otherText="9 Courses Available"
          />
          <CourseCard
            src={phc}
            boldText="Primary Health Care"
            otherText="9 Courses Available"
          />
          <CourseCard
            src={pharmac}
            boldText="Pharmacology"
            otherText="9 Courses Available"
          />
        </div>
        <div className="w-[100%] lg:w-[66%] lg:grid grid-cols-2 lg:justify-items-center">
          <CourseCard
            src={mental}
            boldText="Mental Health"
            otherText="9 Courses Available"
          />
          <CourseCard
            src={research}
            boldText="Research Methodology"
            otherText="9 Courses Available"
          />
        </div>
      </div>
    </div>
  );
};

export default Courses;
