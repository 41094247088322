import React, { useState } from "react";
import "./inc.css";
import fullLogo from "../../assets/images/logos/full-logo.png";
import logowhite from "../../assets/images/logos/logoWhite.png";
import xClose from "../../assets/images/icons/x-close.png";

const NavBar = () => {
  const [navVisible, setnavVisible] = useState(false);

  return (
    <div>
      <div className="p-5 flex flex-row space-between md:hidden ">
        <div className="justify-self-start">
          <img src={fullLogo} alt="Digibridge Logo" />
        </div>
        <div className="justify-self-end" onClick={() => setnavVisible(true)}>
          <span className="fa fa-bars"></span>
        </div>
        {navVisible ? (
          <nav
            className="h-full w-[100%] fixed inset-0 z-10 p-[20px]"
            style={{ background: "rgba(20, 54, 233, 1)" }}
          >
            <div className="mb-[40px] text-white flex flex-row justify-between">
              <img src={logowhite} alt="" />
              <div
                style={{ height: "28px", width: "28px" }}
                onClick={() => setnavVisible(false)}
              >
                <img src={xClose} alt="" />
              </div>
              {/* <span className="fa fa-xmark" /> */}
            </div>
            <div className="font text-white font-bold text-2xl border-b-[1px] border-slate-500">
              <ul className="">
                <li className="mb-[24px]">
                  <a href="#course" onClick={() => setnavVisible(false)}>
                    Courses
                  </a>
                </li>
                <li className="mb-[24px]">
                  <a href="#about" onClick={() => setnavVisible(false)}>
                    About Us
                  </a>
                </li>
                <li className="mb-[24px]">
                  <a href="#contact" onClick={() => setnavVisible(false)}>
                    Contact Us
                  </a>
                </li>
                <li className="mb-[44px]">Blog</li>
              </ul>
            </div>
            <div className="pt-2 text-white fa-2x w-[50%] flex justify-between">
              <a
                href="https://twitter.com/digibridgern"
                target="_blank"
                rel="noreferrer"
                className="pr-3"
              >
                <span className="fa fa-twitter" />
              </a>
              <a
                href="https://www.instagram.com/p/CoUaRF1I07O/?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
                className="pr-3"
              >
                <span className="fa fa-instagram" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100090103114034&mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer"
                className="pr-3"
              >
                <span className="fa fa-facebook" />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noreferrer">
                <span className="fa fa-youtube-play" />
              </a>
            </div>
          </nav>
        ) : (
          ""
        )}
      </div>

      <div
        style={{ background: "white", width: "100%" }}
        className="fixed top-0 inset-x-0 git"
      >
        <div
          style={{ background: "white" }}
          className="hidden md:mx[40px] py-[25px] md:flex lg:mx-32 h-[92px] justify-between items-center border-b-2"
        >
          <div>
            <a href="/">
              <img src={fullLogo} alt="Digibridge Logo" />
            </a>
          </div>
          <div className="w-[50%] flex justify-evenly font">
            <div>
              <a href="#course">Courses</a>
            </div>
            <div>
              <a href="#about">About Us</a>
            </div>
            <div>
              <a href="#contact">Contact Us</a>
            </div>
            <div>Blog</div>
          </div>
          <div className=" w-[15%] flex justify-between">
            <a
              href="https://twitter.com/digibridgern"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fa fa-twitter" />
            </a>
            <a
              href="https://www.instagram.com/p/CoUaRF1I07O/?igshid=YmMyMTA2M2Y="
              target="_blank"
              rel="noreferrer"
            >
              <span className="fa fa-instagram" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100090103114034&mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fa fa-facebook" />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noreferrer">
              <span className="fa fa-youtube-play" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NavBar;
