import React from "react";
import AppStore from "../assets/images/icons/AppStore.png";
import google from "../assets/images/icons/google.png";
import screen from "../assets/images/screen.png";

const Download = () => {
  return (
    <div
      className="px-[20px] text-white font overflow-hidden lg:h-[536px] flex lg:px-32"
      style={{ background: "#010470" }}
    >
      <div className="grid sm:grid-cols-1 lg:grid-cols-3 content-center">
        <div className="pt-8 lg:mt-6 lg:col-span-1 mb-10">
          <div className="md:mx-auto md:text-center lg:text-left lg:mt-32">
            <h4 className="section-header">
              Download the DigiBridge Application Now!
            </h4>
            <br />
            <p className="text-sm">
              The Digibridge app is an education application built on cutting-
              edge technology that enhances academic success of nursing
              students, irrespective of their ages or backgrounds.
            </p>
            <br />
            <p className="text-sm">
              Visit Apple store or Play store to download the app today.
            </p>
            <div className="flex py-5 md:justify-center lg:justify-start">
              <div className="mr-1">
                <img src={AppStore} alt="Appstore download icon white" />
              </div>
              <div className="ml-2">
                <img src={google} alt="Google Playstore download icon white" />
              </div>
            </div>
          </div>
        </div>
        <div className="sm:py-7 pb-0 lg:col-span-2 lg:py-0 lg:-ml-30 sm:mt-4">
          <img className="mx-auto" src={screen} alt="Screen" width="70%" />
        </div>
      </div>
    </div>
  );
};

export default Download;
