import React from "react";
import greenImg from "../assets/images/icons/green-head.png";

const CardGreen = ({ boldText, otherText }) => {
  return (
    <div className="card-green ">
      <img src={greenImg} alt={""} className=" w-[24px] h-[24px]" />

      <p className="font font-semibold text-xl card-text-bold pb-2">
        {boldText}
      </p>
      <p className="font text-sm md:text-base">{otherText}</p>
    </div>
  );
};

export default CardGreen;
