import React from "react";

const TestimonialCard = ({ image, name, description }) => {
  return (
    <div className="col-span-2 md:col-span-1">
      <div className="h-[150px] md:h-[260px]" style={{ overflow: "hidden" }}>
        <img class="mx-auto" src={image} alt="testimonal" width="100%" />
      </div>
      <div className="pt-4 text-center">
        <div className="">
          <p className="font text-sm bluetext">
            <b>{name}</b>
          </p>
          <p className="font text-xs">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
