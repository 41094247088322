import React from "react";
import LgOnboarding from "../assets/images/LgOnboarding.png";
import SmOnboarding from "../assets/images/SmOnboarding.png";

const Onboarding = () => {
  return (
    <div
      className="px-[20px] text-white font py-12 text-center"
      style={{ background: "#010470" }}
      id="about"
    >
      <div className="pb-5 lg:px-60">
        <h4 className="section-header">Get Onboarded Easily</h4>
        <p className="pt-4">
          Our platform boasts an array of incredible features, including easy
          navigation and a user-friendly interface. Get ready to thrive
          academically with Digibridge!
        </p>
      </div>
      <div className="hidden lg:block">
        <img className="mx-auto" src={LgOnboarding} alt="Large Onboarding" />
      </div>
      <div className="lg:hidden">
        <img className="mx-auto" src={SmOnboarding} alt="Small Onboarding" />
      </div>
    </div>
  );
};

export default Onboarding;
