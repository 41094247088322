// import logo from './logo.svg';
import "./App.css";
// import CardBlue from './components/cardBlue';
import NavBar from "../src/components/inc/NavBar";
import HeroHeader from "./components/HeroHeader";
import Feature from "./components/features";
import Courses from "./components/courses";
import TopCourses from "./components/TopCourses";
import Footer from "./components/inc/Footer";
import Download from "./components/Download";
import Testimonial from "./components/Testimonial";
import Onboarding from "./components/Onboarding";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <NavBar />
      <HeroHeader />
      <Feature />
      <Courses />
      <TopCourses />
      <Onboarding />
      <Testimonial />
      <Download />
      <Footer />
    </div>
  );
}

export default App;
