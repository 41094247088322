import React from "react";

import mobileScreen from "../assets/images/screen.png";
import CardBlue from "./cardBlue";
import CardGreen from "./cardGreen";

const Feature = () => {
  return (
    <section className="px-[20px] mt-[50px] md:mx-32 lg:flex lg:flex-row-reverse justify-between lg:px-[0px] lg:py-[10px]">
      <div className="lg:w-[50%]">
        <p className="font font-bold text-xl text-left lg:text-3xl lg:pb-[20px]">
          Amazing Features <span className="bluetext">DigiBridge</span> gives
          for your learning...
        </p>
        <div className="card-container">
          <CardBlue
            boldText="Master Difficult Concepts"
            otherText="At Digibride, we craft our courses to be dynamic, comprehensive, and participatory, emphasizing the acquisition of hands-on expertise that can be readily implemented in practical scenarios."
          />
          <CardGreen
            boldText="Learn on the Go"
            otherText="With just a few taps and swipes on your smartphone or tablet, you can access a bank of educational content, whether you’re commuting to work, waiting in line, or simply taking a break."
          />
          <CardGreen
            boldText="Welcome to personalised and accelerated learning"
            otherText="By utilizing algorithms, the application monitors your advancement and adjusts to your unique learning style."
          />
          <CardBlue
            boldText="Access variety of learning materials"
            otherText="You can access a wealth of information at your fingertips, making learning more accessible and convenient than ever before."
          />
        </div>
      </div>
      <div className="lg:mt-[0px] mt-45 w-[90%] lg:w-[40%] mx-auto lg:mx-[0px]">
        <img src={mobileScreen} alt="Mobile-Screen-Sample" />
      </div>
    </section>
  );
};

export default Feature;
